import axios from 'axios';
import { UserData } from 'src/types';
import LocalStorageService, { LOCAL_STORAGE_KEY } from './localStorageService';

const login = ({ email, password }: { email: string; password: string }) => {
  return axios
    .post(
      `${process.env.NEXT_PUBLIC_DJANGO_URI}/api/login/`,
      {
        email,
        password,
      },
      { withCredentials: true },
    )
    .then((response) => {
      if (response.data) {
        LocalStorageService.setData(LOCAL_STORAGE_KEY.USER, JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_DJANGO_URI}/api/logout/`, { withCredentials: true })
    .then(() => {
      LocalStorageService.removeData(LOCAL_STORAGE_KEY.USER);
    });
};

const getCurrentUser = () => {
  return JSON.parse(LocalStorageService.getData(LOCAL_STORAGE_KEY.USER)) as UserData;
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
};

export default AuthService;
