import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { RefCheckEventMessage, SelectOption, UserData } from 'src/types';
import Select, { SingleValue } from 'react-select';
import useFetchData from 'src/hooks/useFetchData';
import useChangeData from 'src/hooks/useChangeData';
import { useEffect, useState } from 'react';
import { Button } from 'src/components';
import EventWarningModal from './event-warning-modal';
import { getEventMessageOptions } from '../checks-table/utils';
import { toast } from 'react-toastify';

export const UserDetails = ({
  user,
  checkId,
  context,
  hideEventsList,
}: {
  user: UserData;
  checkId: number;
  hideEventsList?: boolean;
  context: string | null;
}) => {
  const [selectedEvent, setSelectedEvent] = useState<SelectOption | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const { data } = useFetchData<RefCheckEventMessage[]>({
    url: `/event-definitions/${context}/`,
  });

  const {
    data: eventData,
    error,
    changeData,
  } = useChangeData<{ id: number; sent: string; msg?: string }>({
    url: `/reference-check/${checkId}/${context}/${user.id}/event/${selectedEvent?.value}/`,
    method: 'POST',
  });

  useEffect(() => {
    if (!eventData) {
      return;
    }
    if (eventData.sent === 'not sent') {
      toast.error(eventData.msg || 'Event could not be sent.');
      return;
    }
    toast.success('Event sent successfully!');
  }, [eventData]);

  useEffect(() => {
    if (error) {
      toast.error('An error occured');
    }
  }, [error]);

  const handleShowModal = () => {
    if (!selectedEvent?.value) {
      return;
    }
    setModalIsOpen(true);
  };

  const handleSendEvent = () => {
    changeData();
    setModalIsOpen(false);
  };

  const handleChangeSelect = (option: SingleValue<SelectOption>) => {
    setSelectedEvent(option);
  };

  return (
    <dl key={user.id} className="grid grid-cols-1 gap-x-10 gap-y-4 sm:grid-cols-2">
      <div className="col-span-1">
        <dt className="text-sm font-semibold text-gray-500">First name</dt>
        <dd className="mt-1 text-sm text-gray-900">{user.firstName}</dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-semibold text-gray-500">Last name</dt>
        <dd className="mt-1 text-sm text-gray-900">{user.lastName}</dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-semibold text-gray-500">Contact</dt>
        <dd className="mt-2 text-sm text-gray-900 space-y-3">
          {!user.emailAddress && !user.telephoneNumber ? <span>-</span> : null}
          {user.emailAddress && (
            <div className="flex text-gray-900 items-center">
              <MailIcon className="text-blue-600 w-5 h-5 mr-2 shrink-0" />
              <span className="inline-block break-words">{user.emailAddress}</span>
            </div>
          )}
          {user.telephoneNumber && (
            <div className="flex text-gray-900 items-center">
              <PhoneIcon className="text-blue-600 w-5 h-5 mr-2 shrink-0" />
              <span className="inline-block">{user.telephoneNumber}</span>
            </div>
          )}
        </dd>
      </div>
      {!context ? (
        <span className="text-red-900 text-sm bg-red-50 p-2 rounded-md border-red-300 border h-min">
          Currently, there are no templates supporting this type of reference
        </span>
      ) : null}

      {context && data && !hideEventsList ? (
        <div className="col-span-1">
          <dt className="text-sm text-gray-700 font-bold">Send an event</dt>
          <div className="mt-1">
            <Select
              isClearable
              onChange={handleChangeSelect}
              options={getEventMessageOptions(data || [])}
            />
            <Button
              disabled={!selectedEvent}
              onClick={handleShowModal}
              className="mt-2 text-sm  w-full"
              secondary
            >
              Send Message/CTA
            </Button>
          </div>
        </div>
      ) : null}
      <EventWarningModal
        message={`You are about to send a ${selectedEvent?.label} to ${user.firstName}  ${user.lastName}. Are you sure?`}
        isOpen={modalIsOpen}
        onSuccess={handleSendEvent}
        onDecline={() => setModalIsOpen(false)}
      />
    </dl>
  );
};
