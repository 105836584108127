import { CheckTypeEnum, UserData } from 'src/types';

export const stateOptions = [
  { label: 'Invited', value: 'invited' },
  { label: 'Created', value: 'created' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'On Hold', value: 'on_hold' },
  { label: 'Completed', value: 'completed' },
];

export const guarantorOptions = [
  { label: 'Has guarantor', value: 'true' },
  { label: 'No guarantor', value: 'false' },
];

export const mineOrUnassignedOptions = [
  { label: 'Mine', value: 'true' },
  { label: 'Unassigned', value: 'false' },
];

export const autoDecisionOptions = [
  { label: 'Pass', value: 'PASS' },
  { label: 'Fail', value: 'FAIL' },
  { label: 'N/A', value: 'PENDING' },
];

export const checkTypeOptions = [
  { label: 'Right to Rent', value: CheckTypeEnum.RIGHT_TO_RENT },
  { label: 'Speedy', value: CheckTypeEnum.SPEEDY },
  { label: 'Full', value: CheckTypeEnum.FULL },
  { label: 'Spotahome Full Check', value: CheckTypeEnum.SPOTAHOME_FULL_CHECK },
  { label: 'Company', value: CheckTypeEnum.COMPANY },
  { label: 'Guarantor', value: CheckTypeEnum.GUARANTOR },
  { label: 'Global full', value: CheckTypeEnum.GLOBAL_FULL },
  {
    label: 'Full Check with Homeppl Guarantid',
    value: CheckTypeEnum.FULL_CHECK_WITH_HOMEPPL_GUARANTID,
  },
  { label: 'Guarantid B2C', value: CheckTypeEnum.GUARANTID_B2C },
  { label: 'Global Guarantid', value: CheckTypeEnum.GLOBAL_GUARANTID },
];

export const getOpsOptionsList = (data: UserData[]) => {
  return data.map((ops) => getOpsOption(ops));
};

export const getOpsOption = (user: UserData) => ({
  label: user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.emailAddress,
  value: user.id,
});

export const getClientsOptions = (data: { name: string }[]) => {
  return data.map((client) => ({
    label: client.name,
    value: client.name,
  }));
};
