export const timeSince = (start: string, precise?: boolean) => {
  if (!start) {
    return '';
  }
  const startDate = new Date(start);
  const endDate = new Date();
  const seconds = Math.floor((endDate.valueOf() - startDate.valueOf()) / 1000);

  let interval = seconds / 31536000;

  const formatTime = (time: number, type: string) => {
    return precise && seconds > 86400
      ? `${startDate.toLocaleDateString('en-GB')} ${startDate.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
        })}`
      : `${time} ${type}${time === 1 ? '' : 's'} ago`;
  };

  if (interval > 1) {
    return formatTime(Math.floor(interval), 'year');
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return formatTime(Math.floor(interval), 'month');
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return formatTime(Math.floor(interval), 'day');
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return formatTime(Math.floor(interval), 'hour');
  }

  interval = seconds / 60;
  if (interval > 1) {
    return formatTime(Math.floor(interval), 'minute');
  }
  return formatTime(Math.floor(seconds), 'second');
};
