import { ReferenceCheck } from 'src/types';
import Router from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppState } from './useAppState';
import LocalStorageService, { LOCAL_STORAGE_KEY } from 'src/services/localStorageService';
import { filtersToString } from 'src/dashboard/dashboard-filters/filtersToString';
import axios, { AxiosError } from 'axios';

type Params = {
  page: number;
  filters: string;
  sort: { key: string; asc: boolean };
};

type Return = {
  data?: { referenceChecks: Record<string, ReferenceCheck>; count: number };
  loading: boolean;
  error: string;
  refreshData: () => void;
};

export const useRefrenceChecksData = ({ page, filters, sort }: Params): Return => {
  const [data, setData] = useState<{ results: ReferenceCheck[]; count: number } | undefined>();
  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState<string>('');

  const isMounted = useRef(false);

  const [referenceChecks, setReferenceChecks] = useAppState<Record<number, ReferenceCheck>>(
    'referenceChecks',
    {},
  );

  const fetchData = useCallback(
    async (url: string) => {
      const requestUrl =
        process.env.NODE_ENV === 'development'
          ? `/api/django?uri=${url}`
          : `${process.env.NEXT_PUBLIC_DJANGO_URI}/api${url}`;

      try {
        setLoading(true);
        setError('');

        const newData = await axios
          .get<{ results: ReferenceCheck[]; count: number }>(requestUrl, {
            withCredentials: true,
          })
          .then(({ data: d }) => d);

        setLoading(false);
        setData(newData);

        if (newData?.results) {
          setReferenceChecks(
            newData.results.reduce((prev, crt) => ({ ...prev, [crt.uuid]: crt }), {}),
          );
        }
      } catch (e) {
        console.error(e);

        setLoading(false);
        setError((e as AxiosError)?.message || 'Unexpected error');

        if ((e as AxiosError)?.response?.status === 403) {
          Router.push('/logout');
        }
      }
    },
    [setData, setReferenceChecks],
  );

  // initial fetch
  useEffect(() => {
    const localStorageFilters = LocalStorageService.getData(LOCAL_STORAGE_KEY.DASHBOARD_FILTERS);
    const stringFilters = filtersToString(JSON.parse(localStorageFilters));

    fetchData(`/reference-checks/list-filter/?page=1&${stringFilters}`);
  }, []);

  // fetch when page/filters/sort change
  useEffect(() => {
    if (isMounted.current) {
      refreshData();
    } else {
      isMounted.current = true;
    }
  }, [page, filters, sort]);

  const refreshData = useCallback(() => {
    fetchData(
      `/reference-checks/list-filter/?page=${page}${filters ? '&' : ''}${filters}&ordering=${
        sort.asc ? '' : '-'
      }${sort.key}`,
    );
  }, [page, filters, sort, fetchData]);

  return {
    data: { referenceChecks, count: data?.count || 0 },
    loading,
    error,
    refreshData,
  };
};
