import { Disclosure } from '@headlessui/react';
import {
  BadgeCheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilAltIcon,
  XIcon,
} from '@heroicons/react/solid';
import { GuarantorType } from 'src/types';
import { getContextForReferee, getReferenceCheckLabel } from '../checks-table/utils';
import ActivityStream from './activity-stream';
import { UserDetails } from './user-details';
import { IconProps } from 'src/components/types';
import { IconButton, Spinner } from 'src/components';
import { ReferenceCheckSection } from './reference-check-section';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelectedReferenceCheckData } from 'src/hooks/useSelectedReferenceCheckData';
import { useEffect } from 'react';

const guarantorToUserData = (guarantor: GuarantorType) => ({
  id: guarantor.id,
  firstName: guarantor.first_name,
  lastName: guarantor.last_name,
  emailAddress: guarantor.email,
  telephoneNumber: guarantor.phone,
});

interface CheckDetailsProps {
  checkId: number;
  isGuarantor?: boolean;
  onClose: () => void;
}

export const ExternalLink = ({
  label,
  href,
  Icon,
  target,
  testId,
}: {
  label: string;
  href: string;
  target?: string;
  testId?: string;
  Icon: React.ComponentType<IconProps>;
}) => (
  <a
    data-testId={testId}
    className="flex text-gray-900 items-center"
    href={href}
    target={target}
    rel="noreferrer"
  >
    <Icon className="text-blue-600 w-5 h-5 mr-2 shrink-0" />
    <span className="inline-block truncate">{label}</span>
  </a>
);

const CheckDetails = ({ checkId, isGuarantor, onClose }: CheckDetailsProps) => {
  const { data: check, loading, error } = useSelectedReferenceCheckData(checkId);

  useEffect(() => {
    if (!error) {
      return;
    }
    toast.error('An error occured');
  }, [error]);

  if (loading || !check) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      data-testid="check-sidebar"
      className="bg-white shadow overflow-hidden sm:rounded-lg min-h-screen"
    >
      <div
        className="px-4 py-3 sm:px-6 flex justify-between items-center"
        data-testid="check-title"
      >
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 flex flex-col">
            {isGuarantor ? (
              <>
                {check.guarantor.first_name} {check.guarantor.last_name}
                <span className="mt-2 text-sm font-normal text-gray-500">
                  Guarantor for{' '}
                  <span className="font-medium">
                    {check.candidate.firstName} {check.candidate.lastName}
                  </span>
                </span>
              </>
            ) : (
              <span>
                {check.candidate.firstName} {check.candidate.lastName}
              </span>
            )}
          </h3>
        </div>
        <IconButton Icon={XIcon} onClick={onClose} />
      </div>
      <div className="border-t border-gray-200 px-4 pb-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-10 gap-y-4 sm:grid-cols-2 mt-4">
          <ExternalLink
            Icon={PencilAltIcon}
            label="Property"
            target="_blank"
            testId="edit-property-link"
            href={`${process.env.NEXT_PUBLIC_DJANGO_URI}/admin/hppl_refchecks/rentalapplication/${check.property.id}/change`}
          />
          {isGuarantor ? (
            <ExternalLink
              Icon={PencilAltIcon}
              label="Guarantor"
              target="_blank"
              testId="edit-guarantor-link"
              href={`${process.env.NEXT_PUBLIC_DJANGO_URI}/admin/hppl_refchecks/guarantor/${check.guarantor.id}/change/`}
            />
          ) : (
            <ExternalLink
              Icon={PencilAltIcon}
              label="Applicant"
              target="_blank"
              testId="edit-applicant-link"
              href={`${process.env.NEXT_PUBLIC_DJANGO_URI}/admin/hppl_refchecks/applicant/${check.candidate.applicantId}/change`}
            />
          )}
        </dl>

        <div className="-mx-6 text-center border-t border-b border-gray-200 bg-gray-50 py-1 font-medium text-gray-700 mt-4 mb-4">
          <h3>Reference check</h3>
        </div>
        <ReferenceCheckSection check={check} isGuarantor={isGuarantor} />

        <div className="-mx-6 text-center border-t border-b border-gray-200 bg-gray-50 py-1 font-medium text-gray-700 mt-6 mb-4">
          <h3>{isGuarantor ? 'Guarantor' : 'Applicant'}</h3>
        </div>
        <UserDetails
          checkId={check.id}
          context="REFCHECK_MESSAGE_CANDIDATE"
          hideEventsList={isGuarantor}
          user={isGuarantor ? guarantorToUserData(check.guarantor) : check.candidate}
        />

        {check.references.length ? (
          <>
            <div className="-mx-6 text-center border-t border-b border-gray-200 bg-gray-50 py-1 font-medium text-gray-700 mt-6 mb-4">
              <h3>Contacts</h3>
            </div>
            {check.references.map((reference) => (
              <Disclosure key={reference.id}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex space-x-1 my-4 items-center">
                      {open ? (
                        <ChevronDownIcon className="w-4 h-4 text-gray-600" />
                      ) : (
                        <ChevronRightIcon className="w-4 h-4 text-gray-600" />
                      )}
                      <span className="text-sm font-semibold text-gray-700">
                        {reference.referee.firstName} {reference.referee.lastName} (
                        {getReferenceCheckLabel(reference.type)})
                      </span>
                      {reference.completed && <BadgeCheckIcon className="w-5 h-5 text-green-600" />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500 mt-4 ml-5 mb-6">
                      <UserDetails
                        context={getContextForReferee(reference.type)}
                        checkId={check.id}
                        user={{ ...reference.referee, id: reference.id }}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </>
        ) : null}

        {!isGuarantor ? (
          <>
            <div className="-mx-6 text-center border-t border-b border-gray-200 bg-gray-50 py-1 font-medium text-gray-700 mt-6 mb-4">
              <h3>Activity stream</h3>
            </div>
            <ActivityStream checkId={check.id} />
          </>
        ) : null}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover={false}
        transition={Slide}
      />
    </div>
  );
};

export default CheckDetails;
