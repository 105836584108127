import { format, formatDistanceToNow } from 'date-fns';
import {
  CaseNoteType,
  CheckStateEnum,
  CheckStatusEnum,
  CheckTypeEnum,
  RefCheckEventMessage,
  Reference,
  ReferenceCheck,
  ReferenceTypeEnum,
} from 'src/types';

export const formatCheckType = (checkTypeTag: string) =>
  checkTypeTag.charAt(0) + checkTypeTag.replaceAll('_', ' ').slice(1);

export const getReferencesRowColor = (references: Reference[]) => {
  if (!references.length) {
    return '';
  }
  const allCompleted = references.every((ref) => ref.completed);
  if (allCompleted) {
    return 'bg-green-100';
  }
  return 'bg-yellow-100';
};

export function getReferenceCheckLabel(referenceType: ReferenceTypeEnum) {
  switch (referenceType) {
    case ReferenceTypeEnum.EMPLOYER_OR_HR:
      return 'HR/Employer';
    case ReferenceTypeEnum.ACCOUNTANT:
      return 'Accountant';
    case ReferenceTypeEnum.GUARANTOR:
      return 'Guarantor';
    case ReferenceTypeEnum.LANDLORD:
      return 'Landlord';
    case ReferenceTypeEnum.AGENT:
      return 'Agent';
    case ReferenceTypeEnum.FAMILY_MEMBER:
      return 'Family Member';
    case ReferenceTypeEnum.HOUSEMATE:
      return 'Housemate';
    case ReferenceTypeEnum.NEIGHBOR:
      return 'Neighbor';
    case ReferenceTypeEnum.STUDENT_ACCOMMODATION:
      return 'Student Accommodation';
    default:
      return null;
  }
}

export const getColorByCheckType = (reference: ReferenceCheck) => {
  if (!reference.checkTypeTag) {
    return '';
  }
  switch (reference.checkTypeTag.value) {
    case CheckTypeEnum.COMPANY:
      return '!border-l-purple-200';
    case CheckTypeEnum.SPEEDY:
      return '!border-l-cyan-200';
    case CheckTypeEnum.RIGHT_TO_RENT:
      return '!border-l-yellow-200';
    default:
      if (reference.checkTypeTag.value?.includes(CheckTypeEnum.FULL)) {
        return '!border-l-green-200';
      }
      return '';
  }
};

export const getStateLabelAndColor = (reference: ReferenceCheck, isGuarantor?: boolean) => {
  const state = isGuarantor ? reference.guarantor.state : reference.state;

  switch (state) {
    case CheckStateEnum.INVITED:
      return { label: 'Invited', color: 'bg-gray-400' };
    case CheckStateEnum.DATA_GATHERING:
      return { label: ' Created', color: 'bg-orange-500' };
    case CheckStateEnum.AUTHENTICATION:
      return { label: 'Submitted', color: 'bg-blue-500' };
    case CheckStateEnum.COMPLETED:
      return { label: 'Completed', color: 'bg-green-500' };
    case CheckStateEnum.ON_HOLD:
      return { label: 'On hold', color: 'bg-yellow-500' };
    default:
      return { label: '-', color: 'text-gray-700' };
  }
};

export const getStatusLabelAndColor = (reference: ReferenceCheck) => {
  switch (reference.status) {
    case CheckStatusEnum.PENDING:
      return { label: 'Pending', color: 'bg-gray-400' };
    case CheckStatusEnum.APPROVED:
      return { label: 'Approved', color: 'bg-green-500' };
    case CheckStatusEnum.UNAPPROVED:
      return { label: 'Unapproved', color: 'bg-red-500' };
    case CheckStatusEnum.WARNING:
      return { label: 'Ember flag', color: 'bg-yellow-500' };
    case CheckStatusEnum.SUBMITTED:
      return { label: 'Submitted', color: 'bg-blue-500' };
    case CheckStatusEnum.CANCELLED:
      return { label: 'Cancelled', color: 'bg-red-500' };
    case CheckStatusEnum.CANCELLED_NOT_BILLABLE:
      return { label: 'Cancelled', color: 'bg-red-500' };
    case CheckStatusEnum.ON_HOLD:
      return { label: 'On hold', color: 'bg-yellow-500' };
    default:
      return { label: '-', color: 'text-gray-700' };
  }
};

export const getEventMessageOptions = (data: RefCheckEventMessage[]) => {
  return data.map((event) => ({
    label: event.name,
    value: event.id,
  }));
};

export const getContextForReferee = (type: ReferenceTypeEnum) => {
  switch (type) {
    case ReferenceTypeEnum.LANDLORD:
      return 'REFCHECK_MESSAGE_REFEREE_LANDLORD';
    case ReferenceTypeEnum.AGENT:
      return 'REFCHECK_MESSAGE_REFEREE_AGENT';
    case ReferenceTypeEnum.FAMILY_MEMBER:
      return 'REFCHECK_MESSAGE_REFEREE_FAMILY_MEMBER';
    case ReferenceTypeEnum.HOUSEMATE:
      return 'REFCHECK_MESSAGE_REFEREE_HOUSEMATE';
    case ReferenceTypeEnum.NEIGHBOR:
      return 'REFCHECK_MESSAGE_REFEREE_NEIGHBOR';
    case ReferenceTypeEnum.EMPLOYER_OR_HR:
      return 'REFCHECK_MESSAGE_REFEREE_EMPLOYER';
    case ReferenceTypeEnum.ACCOUNTANT:
      return 'REFCHECK_MESSAGE_REFEREE_ACCOUNTANT';
    case ReferenceTypeEnum.GUARANTOR:
      return 'REFCHECK_MESSAGE_REFEREE_GUARANTOR';
  }
  return null;
};

export const getParsedCaseNotes = (caseNotes: string) => {
  try {
    const parsedNotes = JSON.parse(caseNotes) as { history: CaseNoteType[] };
    return parsedNotes.history?.[0]?.comment;
  } catch (e) {
    return caseNotes;
  }
};

export const getCaseNotesTimeStamp = (caseNotes: string) => {
  try {
    const parsedNotes = JSON.parse(caseNotes) as { history: CaseNoteType[] };
    return parsedNotes.history?.[0]?.date
      ? `${formatDistanceToNow(new Date(parsedNotes.history?.[0]?.date), {
          addSuffix: true,
        })}: `
      : '';
  } catch (e) {
    return '';
  }
};
