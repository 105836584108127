export type ReferenceCheck = {
  uuid: string;
  id: number;
  applicant: number;
  accountCreationDateTime: string;
  applicationCompletionDateTime: string;
  applicationStartingDateTime: string;
  applicationSubmissionDateTime: string;
  assignee: UserData | null;
  candidate: UserData & { applicantId: number };
  checkTypeTag?: {
    key: string;
    value: string;
  };
  guarantor: GuarantorType;
  invitationDateTime: string;
  lastUpdated: string;
  issuer: {
    contact: any;
    emailAddress: string;
    name: string;
    telephoneNumber: string;
    type: string;
  };
  notes: any;
  property: {
    id: number;
    address: {
      id: number;
      city: string;
      country: string;
      flat: string;
      formattedAddress: string;
      placeId: string;
      postalCode: string;
      street: string;
      streetNumber: string;
    };
    currency: string;
    rent: number;
  };
  property_notes: string;
  references: Reference[];
  relocationDate: string;
  modularCheckResult: string;
  state: string;
  status: string;
  type: string;
  verifier: any;
};

export type Reference = {
  id: number;
  completed: boolean;
  received: any;
  referee: UserData;
  referrer: number;
  type: ReferenceTypeEnum;
};

export type GuarantorType = {
  uuid: string;
  id: number;
  case_notes: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  references: Reference[];
  state: CheckStateEnum;
};

export type UserData = {
  id: number;
  firstName: string;
  lastName: string;
  telephoneNumber: string;
  emailAddress: string;
};

export enum CheckStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
  WARNING = 'WARNING',
  SUBMITTED = 'SUBMITTED',
  CANCELLED = 'CANCELLED',
  CANCELLED_NOT_BILLABLE = 'CANCELLED_NOT_BILLABLE',
  ON_HOLD = 'ON_HOLD',
}

export enum CheckStateEnum {
  INVITED = 'INVITED',
  DATA_GATHERING = 'DATA_GATHERING',
  AUTHENTICATION = 'AUTHENTICATION',
  COMPLETED = 'COMPLETED',
  ON_HOLD = 'ON_HOLD',
}

export enum AutoDecisionEnum {
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export enum CheckTypeEnum {
  'RIGHT_TO_RENT' = 'RIGHT_TO_RENT',
  'SPEEDY' = 'SPEEDY',
  'FULL' = 'FULL',
  'SPOTAHOME_FULL_CHECK' = 'SPOTAHOME_FULL_CHECK',
  'COMPANY' = 'COMPANY',
  'GUARANTOR' = 'GUARANTOR',
  'GLOBAL_FULL' = 'GLOBAL_FULL',
  'FULL_CHECK_WITH_HOMEPPL_GUARANTID' = 'FULL_CHECK_WITH_HOMEPPL_GUARANTID',
  'GUARANTID_B2C' = 'GUARANTID_B2C',
  'GLOBAL_GUARANTID' = 'GLOBAL_GUARANTID',
}

export enum ReferenceTypeEnum {
  EMPLOYER_OR_HR = 'EMPLOYER_OR_HR',
  ACCOUNTANT = 'ACCOUNTANT',
  GUARANTOR = 'GUARANTOR',
  LANDLORD = 'LANDLORD',
  AGENT = 'AGENT',
  FAMILY_MEMBER = 'FAMILY_MEMBER',
  HOUSEMATE = 'HOUSEMATE',
  NEIGHBOR = 'NEIGHBOR',
  STUDENT_ACCOMMODATION = 'STUDENT_ACCOMMODATION',
}

export type Activity = {
  id: number;
  version: number;
  createdDate: number;
  modifiedDate: number;
  application: string;
  affiliate: string;
  eventDefinitionId: string;
  parentId: number;
  parentEventDefinitionId: number;
  externalId: number;
  referenceCheckId: string;
  transactionId: number;
  eventName: string;
  eventDescription: string;
  eventContext: string;
  userId: string;
  userName: string;
  userEmailAddress: string;
  userRole: string;
  visibilityLevel: string;
  eventExternalName: string;
  children: any[];
};

export type SelectOption = { label: string; value: string | number };

export type RefCheckEventMessage = {
  eventExternalName: string;
  id: number;
  name: string;
  visibilityLevel: string;
};

export type CaseNoteType = {
  userId: number;
  date: string;
  comment: string;
};

export type SelectedCheckType = {
  checkId: number;
  isGuarantor?: boolean | undefined;
} | null;
