import {
  PhoneIcon,
  UserAddIcon,
  UserRemoveIcon,
  MailIcon,
  PencilAltIcon,
} from '@heroicons/react/solid';
import Select, { SingleValue } from 'react-select';
import { useOpsData } from 'src/hooks/useOpsData';
import useChangeData from 'src/hooks/useChangeData';
import { AssignCasePopover } from './assign-case';
import { getOpsOption } from '../dashboard-filters/options';
import EventWarningModal from './event-warning-modal';
import { RefCheckEventMessage, ReferenceCheck, SelectOption } from 'src/types';
import {
  getStateLabelAndColor,
  getStatusLabelAndColor,
  getEventMessageOptions,
} from '../checks-table/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import useFetchData from 'src/hooks/useFetchData';
import { Button, IconButton } from 'src/components';
import { toast } from 'react-toastify';
import { useUpdateRefrenceChecks } from 'src/hooks/useUpdateRefrenceChecks';
import { CaseNotesHistory } from './case-notes-history';
import { getAutoDecisionLabelAndColor } from '../checks-table/row-labels';

export const ReferenceCheckSection = ({
  check,
  isGuarantor,
}: {
  check: ReferenceCheck;
  isGuarantor?: boolean;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<SelectOption | null>(null);

  const { data: operationsData } = useOpsData();

  const {
    removeOps,
    changeOps,
    addCaseNotes,
    error: checkError,
    data: updateCheckResponse,
  } = useUpdateRefrenceChecks();

  const { data: sendEventResponse } = useFetchData<RefCheckEventMessage[]>({
    url: `/event-definitions/REFCHECK_MESSAGE_ISSUER/`,
  });

  const {
    data: eventData,
    error: eventError,
    changeData: sendEvent,
  } = useChangeData<{ id: number; sent: string; msg?: string }>({
    url: `/reference-check/${check.id}/REFCHECK_MESSAGE_ISSUER/${check.id}/event/${selectedEvent?.value}/`,
    method: 'POST',
  });

  useEffect(() => {
    if (!eventData) {
      return;
    }
    if (eventData.sent === 'not sent') {
      toast.error(eventData.msg || 'Event could not be sent.');
      return;
    }
    toast.success('Event sent successfully!');
  }, [eventData]);

  useEffect(() => {
    if (!updateCheckResponse) {
      return;
    }
    toast.success('Changes saved successfully!');
  }, [updateCheckResponse]);

  useEffect(() => {
    if (eventError || checkError) {
      toast.error('An error occured');
    }
  }, [eventError, checkError]);

  const handleSelectOps = (option: SingleValue<SelectOption>) => {
    if (option) {
      changeOps(option.value as number);
    }
  };

  const handleShowModal = () => {
    if (!selectedEvent?.value) {
      return;
    }
    setModalIsOpen(true);
  };

  const handleSendEvent = () => {
    sendEvent();
    setModalIsOpen(false);
  };

  const handleChangeSelect = (option: SingleValue<SelectOption>) => {
    setSelectedEvent(option);
  };

  const handleSaveCaseNotes = (caseNotes: string) => {
    addCaseNotes(caseNotes);
  };

  const state = getStateLabelAndColor(check, isGuarantor);
  const status = getStatusLabelAndColor(check);
  const autoDecision = getAutoDecisionLabelAndColor(check);

  return (
    <dl className="grid grid-cols-1 gap-x-10 gap-y-4 sm:grid-cols-2">
      <div className="col-span-1">
        <dt className="text-sm font-semibold text-gray-500">Owner</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {check.assignee ? (
            <div className="flex space-x-4 items-center">
              <div>
                {check.assignee.firstName} {check.assignee.lastName}
              </div>
              <div className="flex space-x-2">
                <IconButton testId="remove-assignee" onClick={removeOps} Icon={UserRemoveIcon} />
                <AssignCasePopover
                  value={getOpsOption(check.assignee)}
                  icon={PencilAltIcon}
                  operationsData={operationsData}
                  handleSelectOps={handleSelectOps}
                />
              </div>
            </div>
          ) : (
            <AssignCasePopover
              icon={UserAddIcon}
              operationsData={operationsData}
              handleSelectOps={handleSelectOps}
            />
          )}
        </dd>
      </div>
      <div className="grid grid-cols-2">
        <div className="">
          <dt className="text-sm font-semibold text-gray-500">Check status</dt>
          <dd
            className={clsx(
              'mt-1 inline-flex rounded-full px-3 py-2 text-xs font-semibold text-white',
              `${status.color}`,
            )}
          >
            {status.label}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-semibold text-gray-500">Check state</dt>
          <dd
            className={clsx(
              'mt-1 inline-flex rounded-full px-3 py-2 text-xs font-semibold text-white',
              `${state.color}`,
            )}
          >
            {state.label}
          </dd>
        </div>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-semibold text-gray-500">Issuer</dt>
        <dd className="mt-1 text-sm text-gray-900">{check.issuer.name}</dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-semibold text-gray-500">Auto decision</dt>

        <dd
          className={clsx(
            'inline-flex rounded-full px-3 py-2 text-xs font-semibold text-white',
            `${autoDecision.color}`,
          )}
        >
          {autoDecision.label}
        </dd>
      </div>

      <div className="col-span-1">
        <dt className="text-sm font-semibold text-gray-500">Contact</dt>
        {!check.issuer.emailAddress && !check.issuer.telephoneNumber ? <span>-</span> : null}
        <dd className="mt-2 text-sm text-gray-900 space-y-3">
          {check.issuer.emailAddress && (
            <div className="flex text-gray-900 items-center">
              <MailIcon className="text-blue-600 w-5 h-5 mr-2 shrink-0" />
              <span className="inline-block break-words">{check.issuer.emailAddress}</span>
            </div>
          )}
          {check.issuer.telephoneNumber && (
            <div className="flex text-gray-900 items-center">
              <PhoneIcon className="text-blue-600 w-5 h-5 mr-2 shrink-0" />
              <span className="inline-block">{check.issuer.telephoneNumber}</span>
            </div>
          )}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm text-gray-700 font-bold">Send an event</dt>
        <div className="mt-1">
          <Select
            isClearable
            onChange={handleChangeSelect}
            options={getEventMessageOptions(sendEventResponse || [])}
          />
          <Button
            disabled={!selectedEvent}
            onClick={handleShowModal}
            className="mt-2 text-sm  w-full"
            secondary
          >
            Send Message/CTA
          </Button>
        </div>
      </div>

      <CaseNotesHistory
        check={check}
        isGuarantor={isGuarantor}
        saveCaseNotes={handleSaveCaseNotes}
      />
      <EventWarningModal
        message={`You are about to send a ${selectedEvent?.label} to ${check.issuer.name}. Are you sure?`}
        isOpen={modalIsOpen}
        onSuccess={handleSendEvent}
        onDecline={() => setModalIsOpen(false)}
      />
    </dl>
  );
};
