import {
  AutoDecisionEnum,
  CheckStateEnum,
  CheckStatusEnum,
  Reference,
  ReferenceCheck,
  ReferenceTypeEnum,
} from 'src/types';

export const getReferencesRowColor = (references: Reference[]) => {
  if (!references.length) {
    return '';
  }
  const allCompleted = references.every((ref) => ref.completed);
  if (allCompleted) {
    return 'bg-green-100';
  }
  return 'bg-yellow-100';
};

export function getReferenceCheckLabel(referenceType: ReferenceTypeEnum) {
  switch (referenceType) {
    case ReferenceTypeEnum.EMPLOYER_OR_HR:
      return 'HR/Employer';
    case ReferenceTypeEnum.ACCOUNTANT:
      return 'Accountant';
    case ReferenceTypeEnum.GUARANTOR:
      return 'Guarantor';
    case ReferenceTypeEnum.LANDLORD:
      return 'Landlord';
    case ReferenceTypeEnum.AGENT:
      return 'Agent';
    case ReferenceTypeEnum.FAMILY_MEMBER:
      return 'Family Member';
    case ReferenceTypeEnum.HOUSEMATE:
      return 'Housemate';
    case ReferenceTypeEnum.NEIGHBOR:
      return 'Neighbor';
    case ReferenceTypeEnum.STUDENT_ACCOMMODATION:
      return 'Student Accommodation';
    default:
      return null;
  }
}

export const getStateLabelAndColor = (reference: ReferenceCheck, isGuarantor?: boolean) => {
  const state = isGuarantor ? reference.guarantor.state : reference.state;

  switch (state) {
    case CheckStateEnum.INVITED:
      return { label: 'Invited', color: 'bg-gray-400' };
    case CheckStateEnum.DATA_GATHERING:
      return { label: ' Created', color: 'bg-orange-500' };
    case CheckStateEnum.AUTHENTICATION:
      return { label: 'Submitted', color: 'bg-blue-500' };
    case CheckStateEnum.COMPLETED:
      return { label: 'Completed', color: 'bg-green-500' };
    case CheckStateEnum.ON_HOLD:
      return { label: 'On Hold', color: 'bg-yellow-500' };
    default:
      return { label: '-', color: 'text-gray-700' };
  }
};

export const getStatusLabelAndColor = (reference: ReferenceCheck) => {
  switch (reference.status) {
    case CheckStatusEnum.PENDING:
      return { label: 'Pending', color: 'bg-gray-400' };
    case CheckStatusEnum.APPROVED:
      return { label: 'Approved', color: 'bg-green-500' };
    case CheckStatusEnum.UNAPPROVED:
      return { label: 'Unapproved', color: 'bg-red-500' };
    case CheckStatusEnum.WARNING:
      return { label: 'Ember flag', color: 'bg-yellow-500' };
    case CheckStatusEnum.SUBMITTED:
      return { label: 'Submitted', color: 'bg-blue-500' };
    case CheckStatusEnum.CANCELLED:
      return { label: 'Cancelled', color: 'bg-red-500' };
    case CheckStatusEnum.CANCELLED_NOT_BILLABLE:
      return { label: 'Cancelled', color: 'bg-red-500' };
    case CheckStatusEnum.ON_HOLD:
      return { label: 'On Hold', color: 'bg-yellow-500' };
    default:
      return { label: '-', color: 'text-gray-700' };
  }
};

export const getAutoDecisionLabelAndColor = (reference: ReferenceCheck) => {
  switch (reference.modularCheckResult) {
    case AutoDecisionEnum.PASS:
      return { label: 'Pass', color: 'bg-green-500' };
    case AutoDecisionEnum.FAIL:
      return { label: 'Fail', color: 'bg-red-500' };
    default:
      return { label: 'N/A', color: 'bg-gray-400' };
  }
};
