import { CheckIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { ReferenceCheck } from 'src/types';
import {
  getParsedCaseNotes,
  getReferenceCheckLabel,
  getReferencesRowColor,
  getStateLabelAndColor,
} from './utils';

const rowClass = 'px-3 py-2 text-sm text-gray-700';

interface TableRowProps {
  check: ReferenceCheck;
  index: number;
  onClick: () => void;
}

export const GuarantorTableRow = ({ check, index, onClick }: TableRowProps) => {
  const state = getStateLabelAndColor(check, true);

  return (
    <tr
      onClick={onClick}
      key={check.uuid}
      className={clsx('hover:bg-gray-200 divide-x divide-gray-300 !border-t-0', {
        'bg-gray-100': index % 2 === 1,
        'bg-gray-50': index % 2 === 0,
      })}
    >
      {/* NAME */}
      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 font-medium border-t border-gray-300 flex space-x-2">
        <span>
          {check.guarantor.first_name} {check.guarantor.last_name}
        </span>
        {/* <span className={'inline-flex px-2 text-[10px] font-semibold text-white bg-gray-400'}>
          GUARANTOR
        </span> */}
      </td>

      <td />
      <td />
      <td
        className={clsx(
          rowClass,
          'space-y-2 border-t border-gray-200',
          getReferencesRowColor(check.guarantor.references),
        )}
      >
        {check.guarantor.references?.map((ref) => (
          <div key={ref.id} className="flex text-gray-900">
            {ref.completed ? (
              <CheckIcon className="h-5 w-5 text-green-600 mr-1 shrink-0" />
            ) : (
              <CheckIcon className="h-5 w-5 text-gray-400 shrink-0" />
            )}{' '}
            {getReferenceCheckLabel(ref.type)} ({ref.referee.firstName})
          </div>
        ))}
      </td>
      <td />
      <td />
      <td />

      {/* NOTES */}
      <td className={clsx(rowClass, 'space-y-2 border-x border-t border-gray-200')}>
        {check.guarantor.case_notes && (
          <div>
            <div className="text-gray-900 font-medium">Case notes:</div>
            <div className="text-gray-500 line-clamp-3">
              {getParsedCaseNotes(check.guarantor.case_notes)}
            </div>
          </div>
        )}

        <div>
          <div className="text-gray-900 font-medium">Guarantor for:</div>
          <div className="text-gray-500">
            {check.candidate.firstName} {check.candidate.lastName}
          </div>
        </div>
      </td>

      {/* STATUS */}
      <td className={clsx(rowClass, 'border-t border-gray-300')}>
        <span
          className={clsx(
            'inline-flex rounded-full px-3 py-2 text-xs font-semibold text-white',
            `${state.color}`,
          )}
        >
          {state.label}
        </span>
      </td>

      <td />
      <td />
      <td />
    </tr>
  );
};
