import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';

import { Header } from 'src/components';
import { Dashboard } from 'src/dashboard';
import { AppStateContextProvider } from 'src/hooks/useAppState';
import AuthService from 'src/services/authService';
import { UserData } from 'src/types';

const HomePage = () => {
  const [user, setUser] = useState<UserData | null>(null);

  useEffect(() => {
    const crtUser = AuthService.getCurrentUser();
    setUser(crtUser);

    if ((window as any)?.smartlook && crtUser) {
      (window as any).smartlook('identify', crtUser.id, {
        name: `${crtUser.firstName} ${crtUser.lastName}`,
        email: crtUser.emailAddress,
      });
    }
  }, []);

  return (
    <>
      {process.env.NEXT_PUBLIC_SMARTLOOK_ID ? (
        <Script id="smartlook-script" strategy="lazyOnload">
          {`
            window.smartlook||(function(d) {
            var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
            var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
            c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
            })(document);
            smartlook('init', '${process.env.NEXT_PUBLIC_SMARTLOOK_ID}', { region: 'eu' });
        `}
        </Script>
      ) : null}
      <AppStateContextProvider>
        <Head>
          <title>Homeppl Ops</title>
        </Head>

        <Header user={user} currentPage="Dashboard" />
        <Dashboard />
      </AppStateContextProvider>
    </>
  );
};

export default HomePage;
