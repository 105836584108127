import React, { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CalendarIcon, SearchIcon } from '@heroicons/react/solid';
import { Input, Button } from 'src/components';
import { IconProps } from 'src/components/types';
import {
  checkTypeOptions,
  guarantorOptions,
  mineOrUnassignedOptions,
  stateOptions,
  getClientsOptions,
  getOpsOptionsList,
  autoDecisionOptions,
} from './options';
import { DashboardFiltersEnum } from './types';
import useFetchData from 'src/hooks/useFetchData';
import { useOpsData } from 'src/hooks/useOpsData';
import { SelectOption } from 'src/types';
import LocalStorageService, { LOCAL_STORAGE_KEY } from 'src/services/localStorageService';
import { filtersToString } from './filtersToString';

const DASHBOARD_FILTERS_LS = 'dashboard-filters';

interface DashboardFiltersProps {
  onSubmit: (filters: string) => void;
  children?: React.ReactNode;
}

const InputIcon = ({
  Icon,
  ...props
}: IconProps & {
  Icon: React.ComponentType<IconProps>;
}) => <Icon {...props} className="w-5 h-5 text-gray-500" />;

const DashboardFilters = ({ onSubmit, children }: DashboardFiltersProps) => {
  const [filters, setFilters] = useState<Partial<Record<DashboardFiltersEnum, string>>>({});
  const [dateFilters, setDateFilters] = useState<Partial<Record<DashboardFiltersEnum, Date>>>({});
  const [selectFilters, setSelectFilters] = useState<
    Partial<Record<DashboardFiltersEnum, SelectOption | SelectOption[]>>
  >({});

  const { data: operationsData } = useOpsData();
  const { data: clientsData } = useFetchData<{ name: string }[]>({
    url: `/reference-checks/get-clients/`,
  });

  useEffect(() => {
    try {
      const dashboardFilters = LocalStorageService.getData(LOCAL_STORAGE_KEY.DASHBOARD_FILTERS);
      const savedFilters = JSON.parse(dashboardFilters);

      if (savedFilters.filters) {
        setFilters(savedFilters.filters);
      }

      if (savedFilters.dateFilters) {
        setDateFilters(
          Object.keys(savedFilters.dateFilters).reduce((acc, key) => {
            const dateString = savedFilters.dateFilters[key as DashboardFiltersEnum];
            const timestamp = Date.parse(dateString);
            if (isNaN(timestamp)) {
              return acc;
            }
            const date = new Date(dateString);

            return { ...acc, [key]: date };
          }, {}),
        );
      }

      if (savedFilters.selectFilters) {
        setSelectFilters(savedFilters.selectFilters);
      }
    } catch (e) {
      LocalStorageService.removeData(LOCAL_STORAGE_KEY.DASHBOARD_FILTERS);
      console.error(e);
    }
  }, []);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    onSubmit(filtersToString({ filters, dateFilters, selectFilters }));

    LocalStorageService.setData(
      LOCAL_STORAGE_KEY.DASHBOARD_FILTERS,
      JSON.stringify({ filters, selectFilters, dateFilters }),
    );
  };

  const handleClear = () => {
    setFilters({});
    setDateFilters({});
    setSelectFilters({});
    onSubmit('');

    localStorage.removeItem(DASHBOARD_FILTERS_LS);
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (!name) {
      return;
    }
    setFilters((f) => ({ ...f, [name]: value }));
  };

  const handleDateChange = (name: string, value: Date | null) => {
    setDateFilters((f) => ({ ...f, [name]: value }));
  };
  const handleSelectChange = (name: string, value: SelectOption | SelectOption[]) => {
    setSelectFilters((f) => ({ ...f, [name]: value }));
  };

  return (
    <form onSubmit={handleSubmit} className="grid grid-cols-6 gap-3 items-start">
      <div>
        <DatePicker
          selected={dateFilters[DashboardFiltersEnum.DATE_AFTER]}
          onChange={(value) => handleDateChange(DashboardFiltersEnum.DATE_AFTER, value)}
          placeholderText="Start date"
          customInput={
            <Input
              value={filters[DashboardFiltersEnum.DATE_AFTER] || ''}
              iconRight={(props) => <InputIcon {...props} Icon={CalendarIcon} />}
            />
          }
        />
      </div>

      <div>
        <DatePicker
          selected={dateFilters[DashboardFiltersEnum.DATE_BEFORE]}
          onChange={(value) => handleDateChange(DashboardFiltersEnum.DATE_BEFORE, value)}
          placeholderText="End date"
          customInput={
            <Input
              value={filters[DashboardFiltersEnum.DATE_BEFORE] || ''}
              iconRight={(props) => <InputIcon {...props} Icon={CalendarIcon} />}
            />
          }
        />
      </div>
      <Input
        name={DashboardFiltersEnum.NAME}
        value={filters[DashboardFiltersEnum.NAME] || ''}
        onChange={handleTextInputChange}
        iconRight={(props) => <InputIcon {...props} Icon={SearchIcon} />}
        placeholder="Name"
      />
      <Input
        name={DashboardFiltersEnum.EMAIL}
        value={filters[DashboardFiltersEnum.EMAIL] || ''}
        onChange={handleTextInputChange}
        iconRight={(props) => <InputIcon {...props} Icon={SearchIcon} />}
        placeholder="Email"
      />
      <Input
        name={DashboardFiltersEnum.PROPERTY}
        value={filters[DashboardFiltersEnum.PROPERTY] || ''}
        onChange={handleTextInputChange}
        iconRight={(props) => <InputIcon {...props} Icon={SearchIcon} />}
        placeholder="Postal code/address"
      />

      <Select
        isMulti
        openMenuOnFocus
        closeMenuOnSelect={false}
        options={stateOptions}
        placeholder="Status"
        value={selectFilters[DashboardFiltersEnum.STATUS] || []}
        onChange={(value) =>
          handleSelectChange(DashboardFiltersEnum.STATUS, value as SelectOption[])
        }
        filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
      />
      <Select
        isMulti
        openMenuOnFocus
        closeMenuOnSelect={false}
        placeholder="Clients"
        options={getClientsOptions(clientsData || [])}
        value={selectFilters[DashboardFiltersEnum.CLIENT] || []}
        onChange={(value) =>
          handleSelectChange(DashboardFiltersEnum.CLIENT, value as SelectOption[])
        }
        filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
      />
      <Select
        isMulti
        openMenuOnFocus
        closeMenuOnSelect={false}
        placeholder="Ops"
        options={getOpsOptionsList(operationsData || [])}
        value={selectFilters[DashboardFiltersEnum.OPS] || []}
        onChange={(value) => handleSelectChange(DashboardFiltersEnum.OPS, value as SelectOption[])}
        filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
      />
      <Select
        isClearable
        openMenuOnFocus
        options={guarantorOptions}
        placeholder="Has Guarantor"
        value={selectFilters[DashboardFiltersEnum.HAS_GUARANTOR] || []}
        onChange={(value) =>
          handleSelectChange(DashboardFiltersEnum.HAS_GUARANTOR, value as SelectOption)
        }
        filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
      />
      <Select
        isClearable
        openMenuOnFocus
        options={checkTypeOptions}
        placeholder="Check type"
        value={selectFilters[DashboardFiltersEnum.CHECK_TYPE] || ''}
        onChange={(value) =>
          handleSelectChange(DashboardFiltersEnum.CHECK_TYPE, value as SelectOption)
        }
        filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
      />
      <Select
        isClearable
        openMenuOnFocus
        options={mineOrUnassignedOptions}
        placeholder="Mine/Unassigned"
        value={selectFilters[DashboardFiltersEnum.MINE] || ''}
        onChange={(value) => handleSelectChange(DashboardFiltersEnum.MINE, value as SelectOption)}
        filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
      />
      <Select
        isClearable
        openMenuOnFocus
        options={autoDecisionOptions}
        placeholder="Auto-decision"
        value={selectFilters[DashboardFiltersEnum.AUTO_DECITION] || ''}
        onChange={(value) =>
          handleSelectChange(DashboardFiltersEnum.AUTO_DECITION, value as SelectOption)
        }
      />
      <div className="grid grid-cols-2 gap-3">
        <Button type="submit">Search</Button>
        <Button type="reset" onClick={handleClear} secondary>
          Clear
        </Button>
      </div>
      {children}
    </form>
  );
};

export default DashboardFilters;
