import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import { Button } from 'src/components';

export default function EventWarningModal({
  message,
  isOpen,
  onSuccess,
  onDecline,
}: {
  message: string;
  isOpen: boolean;
  onSuccess: () => void;
  onDecline: () => void;
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[200]" onClose={onDecline}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Send automatic email
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{message}</p>
                </div>

                <div className="mt-4 flex justify-between border-t border-gray-200 pt-4">
                  <Button
                    outline
                    className="text-green-500 !border-green-500 !bg-white flex space-x-2 border-2"
                    onClick={onSuccess}
                    iconLeft={() => <CheckIcon className="w-5 h-5 text-green-500" />}
                  >
                    Yes
                  </Button>
                  <Button
                    outline
                    className="text-red-400 !border-red-400 !bg-white flex space-x-2  border-2"
                    onClick={onDecline}
                    iconLeft={() => <XIcon className="w-5 h-5 text-red-400" />}
                  >
                    No
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
