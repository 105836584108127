import { useEffect } from 'react';
import { ReferenceCheck } from 'src/types';
import { useAppState } from './useAppState';
import useFetchData from './useFetchData';

type Return = {
  data: ReferenceCheck | null;
  loading: boolean;
  error: string;
};

export const useSelectedReferenceCheckData = (checkId: number): Return => {
  const [referenceChecks, setReferenceChecks] = useAppState<Record<number, ReferenceCheck>>(
    'referenceChecks',
    [],
  );
  const [selectedCheckData, setSelectedCheckData] = useAppState<ReferenceCheck | null>(
    'selectedCheckData',
    null,
  );

  const { data, loading, error } = useFetchData<ReferenceCheck>({
    url: `/reference-check/${checkId}/`,
    cacheResult: false,
  });

  useEffect(() => {
    if (data) {
      setSelectedCheckData(data);
      setReferenceChecks({
        ...referenceChecks,
        [data.uuid]: data,
      });
    }
  }, [data]);

  return { data: selectedCheckData, loading, error };
};
