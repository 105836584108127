import { SelectOption } from 'src/types';
import { DashboardFiltersEnum } from './types';

type Filters = {
  filters: Partial<Record<DashboardFiltersEnum, string>>;
  dateFilters: Partial<Record<DashboardFiltersEnum, Date>>;
  selectFilters: Partial<Record<DashboardFiltersEnum, SelectOption | SelectOption[]>>;
};

const stringifyData = (data: Record<string, string>) =>
  Object.keys(data).reduce(
    (prev, crt) => (data[crt] ? `${prev}${prev ? '&' : ''}${crt}=${data[crt]}` : prev),
    '',
  );

export const filtersToString = ({ filters, dateFilters, selectFilters }: Filters) => {
  if (!filters || !dateFilters || !selectFilters) {
    return '';
  }

  const filtersValues = Object.keys(filters).reduce((acc, key) => {
    const value = filters[key as DashboardFiltersEnum];
    if (!value) {
      return acc;
    }
    return { ...acc, [key]: encodeURIComponent(value) };
  }, {});

  const selectFiltersValues = Object.keys(selectFilters).reduce((acc, key) => {
    const value = selectFilters[key as DashboardFiltersEnum];

    const finalValue = Array.isArray(value)
      ? value.reduce((prev, crt) => `${prev}${prev && ','}${crt.value}`, '')
      : value?.value;

    return { ...acc, [key]: finalValue };
  }, {});

  const dateFiltersValues = Object.keys(dateFilters).reduce((acc, key) => {
    const dateValue = dateFilters[key as DashboardFiltersEnum];
    return {
      ...acc,
      [key]:
        typeof dateValue === 'string'
          ? dateValue
          : dateFilters[key as DashboardFiltersEnum]?.toLocaleDateString(),
    };
  }, {});

  return stringifyData({ ...filtersValues, ...dateFiltersValues, ...selectFiltersValues });
};
