import { ChevronDownIcon, ChevronUpIcon, SelectorIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useState } from 'react';

type TableColumn = {
  name: string;
  size: 'sm' | 'md' | 'lg';
  sortKey?: string;
};

const TABLE_COLUMNS: TableColumn[] = [
  { name: 'Name', size: 'lg' },
  { name: 'Property', size: 'lg' },
  { name: 'Issuer', size: 'md' },
  { name: 'Third Party Checks', size: 'lg' },
  { name: 'Move-in date', sortKey: 'relocation_date', size: 'sm' },
  { name: 'Invited', sortKey: 'creation_dtm', size: 'sm' },
  { name: 'Last Submitted', sortKey: 'submission_dtm', size: 'sm' },
  { name: 'Last Updated', sortKey: 'last_updated', size: 'sm' },
  { name: 'Notes', size: 'lg' },
  { name: 'Status', size: 'sm' },
  { name: 'Owner', size: 'sm' },
  { name: 'Check type', size: 'sm' },
];

export const TableHeader = ({
  handleSort,
}: {
  handleSort: (param: { key: string; asc: boolean }) => void;
}) => {
  const [sortKey, setSortKey] = useState('');
  const [asc, setAsc] = useState(false);

  const handleClick = (col: TableColumn) => {
    if (!col.sortKey) {
      return;
    }

    let isAsc = asc;

    if (sortKey === col.sortKey) {
      isAsc = !asc;
    } else {
      isAsc = false;
    }
    setAsc(isAsc);
    setSortKey(col.sortKey);

    handleSort({ key: col.sortKey, asc: isAsc });
  };

  return (
    <thead className="bg-gray-50 divide-y border-b border-gray-300">
      <tr className="divide-x divide-gray-300 ">
        {TABLE_COLUMNS.map((col: TableColumn) => (
          <th
            onClick={() => handleClick(col)}
            key={col.name}
            className={clsx(
              'sticky top-0 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
              { 'cursor-pointer': col.sortKey },
              { 'w-[100px]': col.size === 'sm' },
              { 'w-[150px]': col.size === 'md' },
              { 'w-[200px]': col.size === 'lg' },
            )}
          >
            <div className="flex justify-center">
              {col.name}
              {col.sortKey && col.sortKey !== sortKey && (
                <SelectorIcon className="shrink-0 h-5 w-5 ml-2" />
              )}
              {col.sortKey && col.sortKey === sortKey ? (
                asc ? (
                  <ChevronDownIcon className="shrink-0 h-5 w-5 ml-2" />
                ) : (
                  <ChevronUpIcon className="shrink-0 h-5 w-5 ml-2" />
                )
              ) : null}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
