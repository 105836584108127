import { ServerIcon, UserGroupIcon, UserIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Spinner } from 'src/components';
import useFetchData from 'src/hooks/useFetchData';
import { Activity } from 'src/types';
import { timeSince } from 'src/utils/timeSince';

const getIconAndColor = (role: string) => {
  switch (role) {
    case 'staff':
      return { Icon: UserGroupIcon, color: 'bg-blue-500' };
    case 'candidate':
      return { Icon: UserIcon, color: 'bg-green-500' };
    default:
      return { Icon: ServerIcon, color: 'bg-gray-500' };
  }
};

export default function ActivityStream({ checkId }: { checkId: number }) {
  const { data, loading } = useFetchData<Activity[] | { error: string }>({
    url: `/reference-check/${checkId}/activityfeed`,
    cacheResult: false,
  });

  if (!data || loading) {
    return (
      <div className="w-full py-2">
        <Spinner className="m-auto" small />
      </div>
    );
  }

  if (!Array.isArray(data)) {
    return null;
  }

  const renderRow = (event: Activity, index: number) => {
    const { Icon, color } = getIconAndColor(event.userRole);
    return (
      <li key={event.id}>
        <div className="relative pb-6">
          {index !== data.length - 1 ? (
            <span
              className="absolute top-4 left-2.5 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
          ) : null}
          <div className="relative flex space-x-3 items-center">
            <div>
              <span
                className={clsx(
                  color,
                  'h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white',
                )}
              >
                <Icon className="h-3 w-3 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1 flex justify-between space-x-4">
              <div>
                <p className="text-sm text-gray-500">
                  {event.eventDescription}
                  {' by '}
                  <span className="font-medium text-gray-900">
                    {event.userName.trim() ? event.userName : event.userEmailAddress}
                  </span>
                </p>
              </div>
              <div className="text-right text-xs whitespace-nowrap text-gray-500">
                {timeSince(new Date(event.modifiedDate).toString())}
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  };

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {data.map(renderRow)}
      </ul>
    </div>
  );
}
