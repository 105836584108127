import { Popover, Transition } from '@headlessui/react';
import { getOpsOptionsList } from '../dashboard-filters/options';
import { Fragment } from 'react';
import { IconProps } from 'src/components/types';
import { SelectOption, UserData } from 'src/types';
import Select, { createFilter, SingleValue } from 'react-select';

export const AssignCasePopover = ({
  value,
  operationsData,
  icon: Icon,
  handleSelectOps,
}: {
  value?: SingleValue<SelectOption>;
  icon: React.ComponentType<IconProps>;
  operationsData?: UserData[];
  handleSelectOps: (option: SingleValue<SelectOption>) => void;
}) => (
  <Popover className="relative">
    <Popover.Button
      data-testId="add-assignee"
      className="text-gray-700 border hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-gray-500 dark:text-gray-500 dark:hover:text-white dark:focus:ring-gray-800"
    >
      <Icon className="w-5 h-5" />
    </Popover.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel
        data-testid="assign-ops-popover"
        className="absolute mt-4 left-0 z-10 p-6 w-[350px] bg-white border border-gray-300 drop-shadow-lg sm:rounded-lg"
      >
        <h3 className="text-lg leading-6 font-medium text-gray-900 border-b pb-4 border-gray-300">
          Assign a team member
        </h3>
        <div className="mt-5">
          <Select
            defaultValue={value}
            isClearable
            placeholder="Ops"
            options={getOpsOptionsList(operationsData || [])}
            onChange={handleSelectOps}
            filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
          />
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
);
