import { useCallback, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Router from 'next/router';

type Params = {
  url: string;
  method: 'POST' | 'PUT';
};

type Return<dataShape, paramShape> = {
  data?: dataShape;
  changeData: (params?: paramShape) => void;
  loading: boolean;
  error: string;
};

const useChangeData = <T, P = undefined>({ url, method }: Params): Return<T, P> => {
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const requestUrl =
    process.env.NODE_ENV === 'development'
      ? `/api/django?uri=${url}`
      : `${process.env.NEXT_PUBLIC_DJANGO_URI}/api${url}`;

  const changeData = useCallback(
    async (params) => {
      try {
        setLoading(true);
        setError('');

        switch (method) {
          case 'POST': {
            const data = await axios
              .post<T>(requestUrl, params, {
                withCredentials: true,
              })
              .then(({ data: d }) => d);

            setData(data);
            setLoading(false);
            break;
          }
          case 'PUT': {
            const data = await axios
              .put<T>(requestUrl, params, {
                withCredentials: true,
              })
              .then(({ data: d }) => d);

            setData(data);
            setLoading(false);
            break;
          }
        }
      } catch (e) {
        console.error(e);
        setError((e as AxiosError)?.message || 'Unexpected error');
        setLoading(false);

        if ((e as AxiosError)?.response?.status === 403) {
          Router.push('/login');
        }
      }
    },
    [requestUrl, method, setData, setLoading],
  );
  return { changeData, data, loading, error };
};

export default useChangeData;
