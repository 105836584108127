import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/solid';
import Router from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Button, IconButton } from 'src/components';
import { AppStateContext } from 'src/hooks/useAppState';
import AuthService from 'src/services/authService';
import { CaseNoteType, ReferenceCheck, UserData } from 'src/types';
import { generateHexColour } from 'src/utils/generateHexColour';
import { timeSince } from 'src/utils/timeSince';

export const CaseNotesHistory = ({
  check,
  isGuarantor,
  saveCaseNotes,
}: {
  check: ReferenceCheck;
  isGuarantor?: boolean;
  saveCaseNotes: (caseNotes: string) => void;
}) => {
  const [{ operations }] = useContext(AppStateContext);
  const [caseNotes, setCaseNotes] = useState<string>('');
  const [notesHistory, setNotesHistory] = useState<CaseNoteType[] | null>(null);

  const [user, setUser] = useState<UserData | null>(null);

  useEffect(() => {
    setUser(AuthService.getCurrentUser());
    parseCaseNotes();
  }, []);

  const parseCaseNotes = () => {
    const notes = isGuarantor ? check.guarantor.case_notes : check.notes;

    if (notes) {
      try {
        const parsedNotes = JSON.parse(notes);
        setNotesHistory(parsedNotes.history);
      } catch (e) {
        setCaseNotes(notes);
      }
    } else {
      setNotesHistory([]);
    }
  };

  const handleSaveCaseNotes = () => {
    if (notesHistory) {
      if (!user || !user.id) {
        Router.push('/logout');
        return;
      }

      const newNote: CaseNoteType = {
        comment: caseNotes,
        userId: user.id,
        date: new Date().toISOString(),
      };

      const history = [newNote, ...notesHistory];

      saveCaseNotes(JSON.stringify({ history }));
      setNotesHistory(history);
      setCaseNotes('');
    } else {
      saveCaseNotes(caseNotes);
    }
  };

  const removeNote = (index: number) => {
    if (!notesHistory) {
      return;
    }

    const history = [...notesHistory];
    history.splice(index, 1);

    if (!history.length) {
      saveCaseNotes('');
    } else {
      saveCaseNotes(JSON.stringify({ history }));
    }

    setNotesHistory(history);
  };

  const renderCaseNote = (note: CaseNoteType, index: number) => {
    const opUser = operations[note.userId] || {};

    return (
      <li key={index} className="py-2">
        <div className="flex space-x-3">
          <div
            className="h-6 w-6 rounded-full flex items-center justify-center text-white text-[10px] font-semibold"
            style={{
              backgroundColor: generateHexColour(`${opUser.firstName}${opUser.lastName}`),
            }}
          >
            {opUser.firstName?.[0]?.toUpperCase()}
            {opUser.lastName?.[0]?.toUpperCase()}
          </div>
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-between">
              <div className="flex space-x-2">
                <h3 className="text-sm font-medium text-gray-700">
                  {opUser.firstName || opUser.lastName ? (
                    <>
                      {opUser.firstName} {opUser.lastName}
                    </>
                  ) : (
                    opUser.emailAddress
                  )}
                </h3>
                {opUser.id === user?.id ? (
                  <IconButton
                    className="p-1 -mt-1"
                    iconClassName="w-4 h-4"
                    Icon={TrashIcon}
                    onClick={() => removeNote(index)}
                  />
                ) : null}
              </div>
              <p className="text-sm text-gray-500"> {timeSince(note.date, true)}</p>
            </div>
            <p className="text-sm text-gray-500">{note.comment}</p>
          </div>
        </div>
      </li>
    );
  };
  return (
    <div className="col-span-2 -mt-4">
      <dt className="text-sm text-gray-500 font-bold">Case Notes</dt>
      <div className="mt-1 flex flex-col" data-testId="case-notes">
        <textarea
          rows={2}
          className="p-2 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
          value={caseNotes}
          onChange={(e) => setCaseNotes(e.currentTarget.value)}
        />
        <div className="mt-2 self-end w-[50%] pl-5">
          <Button onClick={handleSaveCaseNotes} className="text-sm self-end w-full" secondary>
            Save Case Notes
          </Button>
        </div>
      </div>
      {notesHistory?.length ? (
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex space-x-1 items-center">
                {open ? (
                  <ChevronDownIcon className="w-4 h-4 text-gray-600" />
                ) : (
                  <ChevronRightIcon className="w-4 h-4 text-gray-600" />
                )}
                <span className="text-sm font-semibold text-gray-700">Case Notes History</span>
              </Disclosure.Button>
              <Disclosure.Panel className="ml-5 mt-2">
                <ul role="list" className="divide-y divide-gray-200">
                  {notesHistory.map(renderCaseNote)}
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ) : null}
    </div>
  );
};
