import { useContext } from 'react';
import { AppStateContext, useAppState } from 'src/hooks/useAppState';
import { ReferenceCheck } from 'src/types';
import useChangeData from './useChangeData';

type Return = {
  removeOps: () => void;
  changeOps: (value: number) => void;
  addCaseNotes: (value: string) => void;
  error: string;
  data: any;
};

export const useUpdateRefrenceChecks = (): Return => {
  const [{ selectedCheck, operations }] = useContext(AppStateContext);
  const [selectedCheckData, setSelectedCheckData] = useAppState<ReferenceCheck | null>(
    'selectedCheckData',
    null,
  );
  const [referenceChecks, setReferenceChecks] = useAppState<Record<number, ReferenceCheck>>(
    'referenceChecks',
    [],
  );

  const {
    changeData: updateCheck,
    error,
    data,
  } = useChangeData<
    Record<string, unknown>,
    Partial<{ assignee: string | number; case_notes: string }>
  >({
    url: `/reference-check/${selectedCheck.checkId}/`,
    method: 'PUT',
  });

  const removeOps = () => {
    updateCheck({ assignee: 'unassign' });
    updateReferenceChecks({ assignee: null });
  };

  const changeOps = (opId: number) => {
    updateCheck({ assignee: opId });
    updateReferenceChecks({ assignee: operations[opId] });
  };

  const addCaseNotes = (caseNotes: string) => {
    const key = selectedCheck.isGuarantor ? 'guarantor_case_notes' : 'case_notes';

    updateCheck({ [key]: caseNotes });
    if (selectedCheck.isGuarantor && selectedCheckData) {
      updateReferenceChecks({
        guarantor: { ...selectedCheckData.guarantor, case_notes: caseNotes },
      });
    } else {
      updateReferenceChecks({ notes: caseNotes });
    }
  };

  const updateReferenceChecks = (params: Partial<ReferenceCheck>) => {
    if (!selectedCheckData) {
      return;
    }

    const newSelectedCheck = { ...selectedCheckData, ...params } as ReferenceCheck;
    setSelectedCheckData(newSelectedCheck);
    setReferenceChecks({
      ...referenceChecks,
      [selectedCheckData.uuid]: newSelectedCheck,
    });
  };

  return { removeOps, changeOps, addCaseNotes, error, data };
};
