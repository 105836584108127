import { format, formatDistanceToNow } from 'date-fns';

const DurationCell = ({
  dateString,
  showTime = true,
}: {
  dateString: string;
  showTime?: boolean;
}) => {
  if (!dateString) {
    return null;
  }

  const d = new Date(dateString);
  return (
    <>
      <p className="whitespace-nowrap mb-1">
        {showTime ? format(d, 'HH:mm dd/MM/yy') : format(d, 'dd/MM/yy')}
      </p>
      <p className="break-words text-grey-800 font-medium">
        (
        {formatDistanceToNow(d, {
          addSuffix: true,
        })}
        )
      </p>
    </>
  );
};
export default DurationCell;
