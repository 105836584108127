import { useEffect } from 'react';
import { UserData } from 'src/types';
import { useAppState } from './useAppState';
import useFetchData from './useFetchData';

type Return = {
  data?: UserData[];
  loading: boolean;
  error: string;
};

export const useOpsData = (): Return => {
  const [, setOperations] = useAppState<Record<number, UserData[]>>('operations', []);

  const { data, loading, error } = useFetchData<{ results: UserData[] }>({
    url: `/system/users/operations/`,
  });

  useEffect(() => {
    if (data?.results) {
      setOperations(data.results.reduce((prev, crt) => ({ ...prev, [crt.id]: crt }), {}));
    }
  }, [data]);

  return { data: data?.results, loading, error };
};
