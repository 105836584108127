import { useState } from 'react';
import { Pagination } from 'src/components';
import ChecksTable from './checks-table';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import CheckDetails from './check-details';
import DashboardFilters from './dashboard-filters';
import clsx from 'clsx';
import { useRefrenceChecksData } from 'src/hooks/useRefrenceChecksData';
import { useAppState } from 'src/hooks/useAppState';
import { SelectedCheckType } from 'src/types';

export const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState('');
  const [sort, setSort] = useState<{ key: string; asc: boolean }>({ key: '', asc: true });

  const [selectedCheck, setSelectedCheck] = useAppState<SelectedCheckType>('selectedCheck', null);

  const { data, loading, refreshData } = useRefrenceChecksData({
    page: currentPage,
    filters,
    sort,
  });

  const closeDialog = () => setSelectedCheck(null);

  const onSubmit = (newFilters: string) => {
    if (filters === newFilters) {
      refreshData();
    } else {
      setFilters(newFilters);
    }
  };

  return (
    <div className={clsx('flex flex-col space-y-4 mt-6 px-6')}>
      <DashboardFilters onSubmit={onSubmit}>
        {data && data.referenceChecks ? (
          <p className="my-auto text-sm text-gray-700">
            Showing <span className="font-medium">{1 + 50 * (currentPage - 1)}</span> to{' '}
            <span className="font-medium">
              {50 * (currentPage - 1) + Object.keys(data.referenceChecks).length}
            </span>{' '}
            of <span className="font-medium">{data?.count}</span> results
          </p>
        ) : null}
      </DashboardFilters>

      <ChecksTable
        loading={loading}
        checks={data?.referenceChecks ? Object.values(data.referenceChecks) : null}
        onSelectCheck={setSelectedCheck}
        onClickSort={setSort}
      />

      {data?.referenceChecks ? (
        <Pagination
          totalResultsCount={data.count}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : null}

      <Drawer
        open={!!selectedCheck}
        onClose={closeDialog}
        direction="right"
        size={600}
        className="overflow-auto"
      >
        {selectedCheck && (
          <CheckDetails
            checkId={selectedCheck.checkId}
            isGuarantor={selectedCheck.isGuarantor}
            onClose={closeDialog}
          />
        )}
      </Drawer>
    </div>
  );
};
