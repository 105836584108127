export enum DashboardFiltersEnum {
  STATUS = 'status',
  NAME = 'applicant_name',
  EMAIL = 'email',
  DATE_AFTER = 'date_after',
  DATE_BEFORE = 'date_before',
  CLIENT = 'client',
  OPS = 'ops',
  MINE = 'mine',
  PROPERTY = 'property',
  HAS_GUARANTOR = 'has_guarantor',
  CHECK_TYPE = 'check_type',
  AUTO_DECITION = 'modular_check_result',
}
