export enum LOCAL_STORAGE_KEY {
  DASHBOARD_FILTERS = 'dashboard-filters',
  USER = 'user',
}

const getData = (key: LOCAL_STORAGE_KEY): string => {
  return localStorage.getItem(key) || '{}';
};

const setData = (key: LOCAL_STORAGE_KEY, value: string) => {
  localStorage.setItem(key, value);
};

const removeData = (key: LOCAL_STORAGE_KEY) => {
  localStorage.removeItem(key);
};

const LocalStorageService = {
  getData,
  setData,
  removeData,
};

export default LocalStorageService;
