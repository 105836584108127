import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Router from 'next/router';
import { useAppState } from './useAppState';

type Params<paramShape> = {
  url: string;
  params?: paramShape;
  cacheResult?: boolean;
};

type Return<dataShape> = {
  data?: dataShape;
  loading: boolean;
  error: string;
  fetchData: () => void;
};

const useFetchData = <T, P = undefined>({
  url,
  params,
  cacheResult = true,
}: Params<P>): Return<T> => {
  const [data, setData] = useAppState<T | undefined>(url, undefined);

  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState<string>('');

  const requestUrl =
    process.env.NODE_ENV === 'production'
      ? `/api/django?uri=${url}`
      : `${process.env.NEXT_PUBLIC_DJANGO_URI}/api${url}`;

  console.log(process.env.NODE_ENV);
  console.log(requestUrl);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError('');

      const newData = await axios
        .get<T>(requestUrl, {
          params,
          withCredentials: true,
        })
        .then(({ data: d }) => d);

      setData(newData);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setError((e as AxiosError)?.message || 'Unexpected error');
      setLoading(false);

      if ((e as AxiosError)?.response?.status === 403) {
        Router.push('/login');
      }
    }
  }, [params, requestUrl, setData]);

  useEffect(() => {
    if (data && cacheResult) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fetchData, data, loading, error };
};

export default useFetchData;
