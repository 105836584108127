import clsx from 'clsx';
import { CheckIcon } from '@heroicons/react/solid';
import { ReferenceCheck } from 'src/types';
import {
  formatCheckType,
  getCaseNotesTimeStamp,
  getColorByCheckType,
  getParsedCaseNotes,
} from './utils';
import {
  getAutoDecisionLabelAndColor,
  getReferenceCheckLabel,
  getReferencesRowColor,
  getStateLabelAndColor,
} from './row-labels';
import { format, formatDistanceToNow } from 'date-fns';
import DurationCell from './duration-cell';

const rowClass = 'px-3 py-4 text-sm text-gray-700';

interface TableRowProps {
  index: number;
  check: ReferenceCheck;
  onClick: () => void;
}

export const TableRow = ({ check, index, onClick }: TableRowProps) => {
  const state = getStateLabelAndColor(check);
  const checkTypeColor = getColorByCheckType(check);
  const autoDecision = getAutoDecisionLabelAndColor(check);

  return (
    <tr
      onClick={onClick}
      key={check.uuid}
      className={clsx('bg-gray-50 hover:bg-gray-200 divide-x divide-gray-300', checkTypeColor, {
        'bg-gray-100': index % 2 === 1,
        'bg-gray-50': index % 2 === 0,
        'border-l-4': checkTypeColor,
      })}
    >
      {/* NAME */}
      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 font-medium break-all">
        {check.candidate.firstName} {check.candidate.lastName}
      </td>

      {/* ADDRESS */}
      <td className={clsx(rowClass, 'max-w-[200px]')}>
        <div className="text-gray-900">
          {check.property.address.streetNumber} {check.property.address.street}
        </div>
        <div className="text-gray-500">
          {check.property.address.city} {check.property.address.postalCode},{' '}
          {check.property.address.country}
        </div>
      </td>

      {/* ISSUER */}
      <td className={rowClass}>
        <div className="text-gray-900">{check.issuer.name}</div>
        <div className="text-gray-500">{check.issuer.contact && `(${check.issuer.contact})`}</div>
      </td>

      {/* THIRD_PARTY_CHECKS */}
      <td className={clsx(rowClass, 'space-y-2', getReferencesRowColor(check.references))}>
        {check.references?.map((ref) => (
          <div key={ref.id} className="flex text-gray-900">
            {ref.completed ? (
              <CheckIcon className="h-5 w-5 text-green-600 mr-1 shrink-0" />
            ) : (
              <CheckIcon className="h-5 w-5 text-gray-400 shrink-0" />
            )}{' '}
            {getReferenceCheckLabel(ref.type)} ({ref.referee.firstName})
          </div>
        ))}
      </td>
      {/* MOVE_IN_DATE */}
      <td className={rowClass}>
        <DurationCell dateString={check.relocationDate} showTime={false} />
      </td>

      {/* INVITED */}
      <td className={rowClass}>
        <DurationCell dateString={check.invitationDateTime} />
      </td>

      {/* LAST SUBMITTED */}
      <td className={rowClass}>
        <DurationCell dateString={check.applicationSubmissionDateTime} />
      </td>
      <td className={rowClass}>
        <DurationCell dateString={check.lastUpdated} />
      </td>

      {/* NOTES */}
      <td className={clsx(rowClass, 'space-y-2')}>
        {check.notes && (
          <div>
            <div className="text-gray-900 font-medium">Case notes:</div>

            <div className="text-gray-500 line-clamp-3">
              <span className="text-gray-600 font-medium">
                {getCaseNotesTimeStamp(check.notes)}
              </span>
              {getParsedCaseNotes(check.notes)}
            </div>
          </div>
        )}

        {check.property_notes && (
          <div>
            <div className="text-gray-900 font-medium">Property notes:</div>
            <div className="text-gray-500 line-clamp-3">{check.property_notes}</div>
          </div>
        )}

        {check.guarantor?.id && (
          <div>
            <div className="text-gray-900 font-medium">Has guarantor:</div>
            <div className="text-gray-500">
              {check.guarantor.first_name} {check.guarantor.last_name}
            </div>
          </div>
        )}
      </td>

      {/* STATUS */}
      <td className={rowClass}>
        <span
          className={clsx(
            'inline-flex rounded-full px-3 py-2 text-xs font-semibold text-white whitespace-nowrap',
            `${state.color}`,
          )}
        >
          {state.label}
        </span>
      </td>

      {/* OWNER */}
      <td className={rowClass}>
        {check.assignee?.firstName} {check.assignee?.lastName}
      </td>

      {/* CHECK TYPE */}
      <td className={clsx(rowClass, 'font-medium')}>
        {check.checkTypeTag?.value && formatCheckType(check.checkTypeTag.value)}
      </td>
    </tr>
  );
};
