import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Spinner } from 'src/components';
import { ReferenceCheck, SelectedCheckType } from 'src/types';
import { GuarantorTableRow } from './guarantor-table-row';
import { TableHeader } from './table-header';
import { TableRow } from './table-row';

interface ReferenceChecksTableProps {
  checks: ReferenceCheck[] | null;
  loading: boolean;
  onSelectCheck(params: SelectedCheckType): void;
  onClickSort(param: { key: string; asc: boolean }): void;
}

const ChecksTable = ({
  loading,
  checks,
  onSelectCheck,
  onClickSort,
}: ReferenceChecksTableProps) => {
  const renderTableRow = useCallback(
    (check: ReferenceCheck, index: number) => {
      return (
        <React.Fragment key={check.uuid}>
          <TableRow
            index={index}
            check={check}
            onClick={() => onSelectCheck({ checkId: check.id })}
          />
          {check.guarantor?.id ? (
            <GuarantorTableRow
              index={index}
              check={check}
              onClick={() => onSelectCheck({ checkId: check.id, isGuarantor: true })}
            />
          ) : null}
        </React.Fragment>
      );
    },
    [onSelectCheck],
  );

  return (
    <div className="flex flex-col">
      <div className="shadow ring-1 ring-black ring-opacity-5">
        <table className="relative min-w-full divide-y divide-gray-300">
          <TableHeader handleSort={onClickSort} />
          {!loading && checks ? (
            <tbody className={clsx('divide-y divide-gray-300 bg-white')}>
              {checks.map(renderTableRow)}
            </tbody>
          ) : null}
        </table>
        {loading || !checks ? (
          <div className="w-full h-[calc(100vh-280px)]  flex items-center justify-center">
            <Spinner />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChecksTable;
