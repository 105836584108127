/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, createContext, useState, useEffect } from 'react';

type AppContextPaylaod = [Record<string, any>, (value: any) => void];
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const AppStateContext = createContext<AppContextPaylaod>([{}, () => {}]);

export const AppStateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = useState({});

  const updateValue = (val: any) => {
    setValue(val);
  };

  return (
    <AppStateContext.Provider value={[value, updateValue]}>{children}</AppStateContext.Provider>
  );
};

export function useAppState<T>(key: string, initialValue: T) {
  const [value, setValue] = useContext(AppStateContext);

  useEffect(() => {
    if (!Object.keys(value).includes(key)) {
      setValue((v: T) => ({ ...v, [key]: initialValue }));
    }
  }, []);

  const updateValue = (values: T) => {
    setValue((v: T) => ({ ...v, [key]: values }));
  };
  return [value[key] as T, updateValue] as const;
}
